
import { defineComponent, onMounted, inject, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import TableLite from "vue3-table-lite/ts";
import type { Component } from "vue";

// services
import tokenService from "@/services/token.service";

// composables
import useDateFormatter from "@/composables/dateFormatter";
import useTokenStatusBadge from "@/composables/tokenStatusBadge";

// components
import Badge from "@/components/atoms/Badge.atoms.vue";

// interfaces
import {
  TypeEnum,
  ITokenHistory,
  ITokenHistoryResult,
} from "@/interfaces/token";

export default defineComponent({
  components: { Badge, "table-lite": TableLite as unknown as Component },
  setup() {
    const setPageTitle = inject("setPageTitle") as CallableFunction;
    onMounted(() => setPageTitle("Riwayat Pembelian"));

    const { formatDateTime } = useDateFormatter();

    const router = useRouter();

    let state = reactive({ data: {} as ITokenHistoryResult });
    const isLoading = ref(false);
    const type = ref("");
    const status = ref("");

    const downloadResult = async (url: string) => {
      window.open(url, "_blank");
    };

    // Init Your table settings
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Tipe Pembelian / Deskripsi",
          field: "description",
          width: "20%",
          sortable: true,
          isKey: true,
        },
        {
          label: "Status",
          field: "status",
          width: "10%",
          sortable: true,
        },
        {
          label: "Kapan Dibuat",
          field: "created_at",
          width: "10%",
          sortable: true,
        },
        {
          label: "Total",
          field: "token",
          width: "10%",
          sortable: true,
        },
        {
          label: "Aksi",
          field: "action",
          width: "5%",
          sortable: false,
        },
      ],
      rows: [] as ITokenHistory[],
      totalRecordCount: 0,
      sortable: {
        order: "id",
        sort: "asc",
      },
    });

    /**
     * Table search event
     */
    const doSearch = async (
      offset: number,
      limit: number,
      order: string,
      sort: string
    ) => {
      const page = offset / limit + 1;

      table.isLoading = true;
      const { data, total } = await tokenService.getHistory(
        page,
        limit,
        status.value,
        type.value
      );
      table.rows = data.map((d: ITokenHistory) => {
        d.created_at = formatDateTime(d.created_at, "DD/MM/YYYY");

        return d;
      });

      table.totalRecordCount = total;
    };

    /**
     * Table search finished event
     */
    const tableLoadingFinish = () => {
      table.isLoading = false;
    };

    // Get data first
    doSearch(0, 10, "id", "desc");

    // Filter
    const onTypeChange = (e: any) => {
      const typeVal = e.target.value;
      type.value = typeVal;
      doSearch(0, 10, "id", "desc");
    };

    const onStatusChange = (e: any) => {
      const statusVal = e.target.value;
      status.value = statusVal;
      doSearch(0, 10, "id", "desc");
    };

    // Actions
    const actionView = (id: number) => {
      router.push(`/admin/token-history/${id}`);
    };

    const showActionView = (type: string) => {
      return type === TypeEnum.Invite;
    };

    const { getBadgeVariant } = useTokenStatusBadge();

    return {
      state,
      isLoading,
      table,
      downloadResult,
      doSearch,
      tableLoadingFinish,
      actionView,
      showActionView,
      getBadgeVariant,
      onTypeChange,
      onStatusChange,
    };
  },
});
