import { TokenStatusEnum } from "@/interfaces/token";

export default function useTokenStatusBadge(): any {
  // get badge class by status
  const getBadgeVariant = (status: string): string => {
    const badgeStatus: { [key: string]: string } = {
      [TokenStatusEnum.Draft]: "light-gray",
      [TokenStatusEnum.WaitingPayment]: "blue",
      [TokenStatusEnum.Canceled]: "red",
      [TokenStatusEnum.Done]: "green",
    };

    return badgeStatus[status] || "light-gray";
  };

  return {
    getBadgeVariant,
  };
}
