export enum TokenStatusEnum {
  Draft = "Draft",
  WaitingPayment = "Menunggu Pembayaran",
  Canceled = "Batal",
  Done = "Selesai",
}

export enum TypeEnum {
  Topup = "topup",
  Refund = "refund",
  Invite = "invite participant",
}

export interface ITokenHistory {
  id: number;
  description: string;
  status: string;
  status_label: string;
  token: number;
  created_at: string;
}

export interface ITokenHistoryResult {
  current_page: number;
  data: ITokenHistory[];
  from: number;
  to: number;
  total: number;
}

export interface IHistoryDetailUser {
  email: string;
  token: number;
}

export interface IHistoryDetail {
  id: number;
  company_id: number;
  description: string;
  invoice_number: string;
  token: number;
  status: TokenStatusEnum;
  status_label?: string;
  type?: string;
  additional_data: IHistoryDetailUser[];
}
